const initialState = {
	authStatus:false,
	loaded:false,
	authError: null,
	authData:{}
}
export default function (state=initialState, action){

	switch(action.type){
		case "GET_AUTHENTICATION":
			return {...state,
				authStatus:action.payload.status,
				loaded:action.payload.loaded,
				authError:action.payload.errorMessage,
				authData:action.payload.user
			}
           break; 		
	}
	return state
}