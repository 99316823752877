import React, { Component, Suspense } from 'react';


class MainFooter extends Component {

	render(){
	  return (
	  		<div className="footer">
		  		<div className="container-fluid d-flex align-items-center justify-content-between">
		  			<img src="./assets/images/footer-logo.svg" className="img-fluid" alt="Logo"/>
		  			<span>© 2021 company <a href="#">Terms & Condition</a> | <a href="#">Privacy Policy</a></span>
		  		</div>
	  		</div>
	  	);
  	}
}

export default MainFooter;

