import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination } from '@material-ui/core';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import moment from 'moment';
 
import { Button } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';

import GetPitchAPI from '../services/getPitchData';
import GetCommonAPI from '../services/getCommonData';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            backgroundColor: '#fff',
        },
    },
};


class PitchesCampaigns extends Component {
    constructor(props) {
        super(props);

        this.state = {
            PitchData:'',
            count:"",
            skip:0, 
            limit: 10,
            pageNumber: 0  ,
            pitchType:'pitches' ,
            CommonData:''
        };
    }

    componentWillMount(){
        let urlString = window.location.href;
        let filterValue = "pitches";
        if(urlString.split('?').length>1){
          filterValue = "Event, Festival, Charity Ball, Launch";
          this.setState({
            pitchType: "Event, Festival, Charity Ball, Launch"
          });
        }
        
        window.scrollTo(0,0);
        this.getCommonData();
        this.getApiFunction(this.state.skip, 0, filterValue)
        this.setState({
             loading: true 
        });
    
    }

    getCommonData(){
      GetCommonAPI()
      .then((response) => {
                this.setState({
                    CommonData: response.data, 
                    loading: false 
                });
            })
            .catch((error) => {
                console.log("error: ",error)
                this.setState({
                    loading: false 
                });
            })
    }


    getApiFunction(skip, page, pitchType){
        GetPitchAPI(localStorage.adminToken, skip, pitchType)
            .then((response) => {
                this.setState({
                    PitchData: response.data.data,
                    count: response.data.count,
                    loading: false 
                });
            })
            .catch((error) => {
                console.log("error: ",error)
                this.setState({
                    loading: false 
                });
            })
    }

    nextPage(e, page){
       if(page == '01'){
        page = 1
       }
       let skipValue = page*this.state.limit;
       this.setState({
           skip: skipValue,
           pageNumber: page
       });
       this.getApiFunction(skipValue,page)

    }
    selectFilter(value){
        this.setState({
            pitchType: value 
        });
        this.getApiFunction(0,0,value)
    }

    render() {
        return (
            <div>
              <div className="account-search">
                  <div className="container">
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="search-wrap">
                              <div className="search-item">
                                  <FormControl variant="outlined" className="dropdown-arrow-color">
                                      <InputLabel id="pitchType">Pitch Type</InputLabel>
                                      {this.state.CommonData && (
                                        <Select 
                                          labelId="pitchType"
                                          id="demo-simple-select"
                                          label="Pitch Type"
                                          MenuProps={MenuProps}
                                          value={this.state.pitchType}
                                          onChange={(event) => this.selectFilter(event.target.value)}
                                        >
                                          <MenuItem  value="pitches">All</MenuItem>
                                        {this.state.CommonData.getPitchType.data.displayValues.map((row, index) => (
                                          <MenuItem key={index} value={this.state.CommonData.getPitchType.data.values[index]}>{row}</MenuItem>

                                        ))}
                                        
                                          
                                        </Select>
                                      )}
                                    </FormControl>
                              </div> 
                              
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <div className="dashboad common-table-wrap"> 
                  <div className="container">
                    <div className="common-table pitch-listing-table">
                      <TableContainer>
                        <Table aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Pitch </TableCell>
                              <TableCell align="left">Category</TableCell>
                              <TableCell align="center">Invited</TableCell>
                              <TableCell align="center">Partners</TableCell>
                              <TableCell align="right"></TableCell>
                            </TableRow>
                          </TableHead>
                          {this.state.PitchData &&(
                          <TableBody>
                              {this.state.PitchData.map((row) => (
                                <TableRow key={this.state.PitchData._id}>
                                  <TableCell  className="user-detail" component="th" scope="row">
                                    <div className="table-profile-details d-flex align-items-start">
                                        <img src={row.bannerImageUrl} className="img-fluid pitch-list-image"/>
                                        <div>
                                            <label style={{cursor: 'pointer'}} onClick={() => window.open('https://app.thecaviarspoon.com/pitch/'+row._id)} className="d-block">{row.titleOfThePitch}</label>
                                            <time>{ moment(row.pitchProposedDate).format('LLL') }</time> | <span>{row.pitchLocations.locationString}</span>
                                        </div>
                                    </div>
                                  </TableCell>
                                  <TableCell className="user-detail-url" align="left">{row.category.name}</TableCell>
                                  <TableCell className="user-detail-url" align="center">{row.invited && (<span> {row.invited}</span> )}</TableCell>
                                  <TableCell className="user-detail-url" align="center">{row.partnered && (<span> {row.partnered}</span> )}</TableCell>
                                  
                                  <TableCell className="add-del-btn" align="right">
                                   
                                    <Tooltip title="View" placement="top">
                                        <IconButton aria-label="View" color="primary" onClick={() => window.open('https://app.thecaviarspoon.com/pitch/'+row._id)}>
                                            <img src="./assets/images/icon-view.svg" className="img-fluid" alt="Icon"/>
                                        </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                         )}
                        </Table>
                      </TableContainer>
                    </div>
                      {this.state.PitchData &&(
                      <TablePagination 
                          rowsPerPageOptions={[10]}
                          count={this.state.count}
                          rowsPerPage={this.state.limit}
                          component="div" 
                          page={this.state.pageNumber}
                          onChangePage={(e, page)=>this.nextPage(e, page)}
                       />
                       )}      
                  </div>
              </div>
            </div>
        );
    }
}

export default PitchesCampaigns;