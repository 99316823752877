import axios from 'axios'
import { apiURL } from '../env'
export function loginCheck(email,password){

		return dispatch =>{
			axios({
				url: apiURL+"users/adminLogin",
		    	method: "POST",
		    	data: {
		    		"username": email.toLowerCase(),
            		"password": password
            	}
			}).then((response) =>{
					localStorage.adminEmail=response.data.user.email;
					localStorage.adminToken=response.data.token;
					axios.defaults.headers.common['Authorization'] = response.data.token;
					 dispatch({
						type:"GET_AUTHENTICATION",
						payload:{user:response.data.user, status:true,loaded:true,errorMessage:null}
					})
					window.location.href= "/#/dashboard"; 

			}).catch((error) =>{
				console.log("errr login-->",error)
				dispatch({
					type:"GET_AUTHENTICATION",
					payload:{status:false,loaded:true,errorMessage:error.message}
				})
			})
	}
}
