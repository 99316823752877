import React, { Component, Suspense } from 'react';
import logo from '../../logo.svg';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ApprovalsListAPI from '../services/awaitingApprovals.js';
import RejectedListAPI from '../services/rejectedUsers.js';
import ApprovalAPI from '../services/approveRejectUser.js';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import moment from  'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class UserList extends Component {
	constructor(props){
		super(props);
		this.state = {
			selectedTab: 0,
			approvalList: [],
			rejectedList: [],
			dialogOpen: false,
			notification: false,
			notificationType: '',
			notificationMessage: '',
			dialogStatus: '',
			selectedUserPlatform: ''
		}
	}
	componentWillMount(){
		this.getApprovalList();
		this.getRejectedList();
	}
	getApprovalList(){
		ApprovalsListAPI()
			.then((res) => {
				this.setState({
					approvalList: res.data.data 
				});
			})
			.catch((err) => {
				console.log("err: ",err)
			})
	}
	getRejectedList(){
		RejectedListAPI()
			.then((res) => {
				this.setState({
					rejectedList: res.data.data 
				});
			})
			.catch((err) => {
				console.log("err: ",err)
			})
	}
	confirmApproval(){
		this.setState({
			dialogOpen: true 
		});
	}
	capitalizeFirstLetter(string) {
	  return string.charAt(0).toUpperCase() + string.slice(1);
	}
	approveRejectUser(status){
		ApprovalAPI(this.state.selectedUser, status, this.state.selectedUserPlatform)
			.then((res) => {
				this.setState({
					dialogOpen: false,
					notification: true,
					notificationType: 'success',
					notificationMessage: this.state.dialogStatus == 'approve'? 'The user is now approved and moved to the accounts.': 'The user request is rejected and move to the rejected tab.' 
				});
				this.getApprovalList();
				this.getRejectedList();
			})
			.catch((error) => {
				console.log("error: ",error)
				this.setState({
					dialogOpen: false,
					notification: true,
					notificationType: 'error',
					notificationMessage: this.capitalizeFirstLetter(error.response.data.error.message)
				});
			})
	}
	render(){ 
	  return (
	    	<div className="common_list">
	    		<div className="tab-head">
		    		<div className="container">
		    		 <Tabs
				        value={this.state.selectedTab}
				        indicatorColor="primary"
				        textColor="primary"
				        onChange={(e, v) => this.setState({selectedTab: v})}
				        aria-label="disabled tabs example"
				      >
				        <Tab label="New" />
				        <Tab label="Rejected" />
				     </Tabs>
				    </div>
			    </div>

			      <div className="container user-table">
			      {this.state.selectedTab == 0 && (
			      	<TableContainer>
				      	<Table aria-label="simple table">
				      	{this.state.approvalList.length <= 0 && (
				        	<caption style={{textAlign: 'center'}}>No requests found.</caption>
				        )}
				        <TableHead>
				          <TableRow>
				            <TableCell>Account</TableCell>
				            <TableCell align="left">Email</TableCell>
				            <TableCell align="left">Date Signed Up</TableCell>
				            <TableCell align="center">Purpose</TableCell>
				            <TableCell align="right"></TableCell>
				          </TableRow>
				        </TableHead>
				        {this.state.approvalList.length > 0 && (
					        <TableBody>
					          {this.state.approvalList.map((row) => (
					            <TableRow key={row._id}>
					              <TableCell  className="user-detail" component="th" scope="row">
					                <b className="d-flex align-items-center" style={{fontSize:"16px", fontWeight :600}}>{row.firstName} {row.lastName} {!this.props.emailVerified &&(<Tooltip placement="top" title="Pending Email Verification"><WarningIcon style={{ fontSize:"18px", color: "#E2C17C" }}/></Tooltip>)} {this.props.emailVerified &&(<Tooltip placement="top" title=" Email Verified"><CheckCircleIcon style={{ fontSize:"20px", color: "green" }}/></Tooltip>)}</b>
					                {row.designation}<br/>
					                {row.account.website && (
					                	<a href={'//'+row.account.website} target="_blank">{row.account.companyName}</a>
					                )}
					                {!row.account.website && (
					                	<a>{row.account.companyName}</a>
					                )}
					              </TableCell>
					              <TableCell className="user-detail-url" align="left"><a href={"mailto:"+row.email}>{row.email}</a></TableCell>
					              <TableCell align="left">{moment(row.createdDate).format('MMM Do YYYY')}</TableCell>
					              <TableCell align="center">{row.platform.name}</TableCell>
					              <TableCell className="add-del-btn" align="right">
					              	<Tooltip title="Approve" placement="top">
						              	<IconButton aria-label="approve" color="primary" onClick={() => {this.setState({dialogOpen: true, selectedUser: row._id, selectedUserPlatform: row['platform']['_id'], dialogStatus: 'approve', dialogTitle:'Approve user', dialogContent: 'Are you sure you want to approve this user?'})}}>
						              		<img src="./assets/images/plus-button.svg" className="img-fluid" alt="Icon"/>
						              	</IconButton>
					              	</Tooltip>
					              	<Tooltip title="Reject" placement="top">
						              	<IconButton aria-label="delete" color="primary" onClick={() => {this.setState({dialogOpen: true, selectedUser: row._id, selectedUserPlatform: row['platform']['_id'], dialogStatus: 'reject', dialogTitle:'Reject user', dialogContent: 'Are you sure you want to reject this user?'})}}>
						              		<img src="./assets/images/delete-button.svg" className="img-fluid" alt="Icon"/>
						              	</IconButton>
					              	</Tooltip>
					              </TableCell>
					            </TableRow>
					          ))}
					        </TableBody>
				        )}
				      </Table>
				    </TableContainer>
				    )}
			      {this.state.selectedTab == 1 && (
				    <TableContainer>
				      <Table  aria-label="simple table">
				      	{this.state.rejectedList.length <= 0 && (
				        	<caption style={{textAlign: 'center'}}>No rejected users found.</caption>
				        )}
				        <TableHead>
				          <TableRow>
				            <TableCell>Account</TableCell>
				            <TableCell align="left">Email</TableCell>
				            <TableCell align="center">Purpose</TableCell>
				          </TableRow>
				        </TableHead>


				        {this.state.rejectedList.length > 0 && (
					        <TableBody>
					          {this.state.rejectedList.map((row) => (
					            <TableRow key={row._id}>
					              <TableCell className="user-detail" component="th" scope="row">
					                <b style={{fontSize:"16px", fontWeight :600}}>{row.firstName} {row.lastName}</b><br/>
					                {row.designation}<br/>
					                {row.account.website && (
					                	<a href={'//'+row.account.website} target="_blank">{row.account.companyName}</a>
					                )}
					                {!row.account.website && (
					                	<a>{row.account.companyName}</a>
					                )}
					              </TableCell>
					              <TableCell className="user-detail-url" align="left"><a href={"mailto:"+row.email}>{row.email}</a></TableCell>
					              <TableCell align="center">{row.platform.name}</TableCell>
					            </TableRow>
					          ))}
					        </TableBody>
				        )} 

				      </Table>
				    </TableContainer>
				    )}
			      	<Dialog
				        open={this.state.dialogOpen}
				        onClose={() => this.setState({dialogOpen: false})}
				        aria-labelledby="alert-dialog-title"
				        aria-describedby="alert-dialog-description"
				    >
				        <DialogTitle style={{backgroundColor:"#000", color:"#fff"}} id="alert-dialog-title">{this.state.dialogTitle}</DialogTitle>
				        <DialogContent>
				          <DialogContentText id="alert-dialog-description">
				            {this.state.dialogContent} 
				          </DialogContentText>
				        </DialogContent>
				        <DialogActions>
				          <Button className="common-button" onClick={() => this.setState({dialogOpen: false})} color="primary">
				            No
				          </Button>
				          <Button className="common-button" onClick={() => this.approveRejectUser(this.state.dialogStatus == 'approve'? true: false)} color="primary" autoFocus>
				            Yes
				          </Button>
				        </DialogActions>
				    </Dialog>

				    <Snackbar open={this.state.notification} autoHideDuration={6000} onClose={()=> this.setState({notification: false})}>
				        <Alert severity={this.state.notificationType} onClose={()=> this.setState({notification: false})}>
				          {this.state.notificationMessage}
				        </Alert>
				    </Snackbar>
			      </div>
	    	</div> 
	  );
  	}
}

export default UserList;


