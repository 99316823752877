import React, { Component, Suspense } from 'react';
import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import Login from './Login/container/Login';
import LoginHeader from './Header/LoginHeader';
import MainHeader from './Header/mainheader';
import UserList from './UserList/container/main';
import accountList from './UserList/container/accountList';
import approvedList from './UserList/container/approvedList';
import dashBoard from './Dashboard/container/main.js';
import PitchesCampaigns from './PitchesCampaigns/container/main.js';
import MainFooter from './Common/Components/footer';
import './stylesheets/style.css'
import './stylesheets/stylesss.css'
import getAuthentication from './actions/verifyToken';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Muli, sans-serif'
  },
  palette: {
    primary: {
      main: '#000000',
      contrastText: '#fff',
    },
    secondary: {
      main: '#E2C17C',
      contrastText: '#000',
    },
  }
});

class App extends Component{
  constructor(props) {
    super(props);
  
    this.state = {
      loggedIn: false
    };
  }
  componentWillMount(){
    this.props.getAuthentication();
  }
  render(){
    let loginRoute = (<div><LoginHeader/> <Route exact path="/" component={Login} />  <Route exact path="/login" component={Login} /></div>);
    let clientRoute = (
      <div>
        <MainHeader />
        <Route exact path="/" component={UserList} />
        <Route exact path="/review" component={UserList} />
        <Route exact path="/accounts" component={accountList} />
        <Route exact path="/approved-list" component={approvedList} />
        <Route exact path="/dashboard" component={dashBoard} />
        <Route exact path="/pitches-campaigns" component={PitchesCampaigns} />
      </div>
    );
    return(
      <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          {this.props.auth.authStatus?clientRoute: loginRoute}
        </Switch>
      </Router>
      <MainFooter/>
      </ThemeProvider>
    )
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({getAuthentication: getAuthentication}, dispatch);
}
export default connect(mapStateToProps, matchDispatchToProps)(App);
