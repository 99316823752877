import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Input from '@material-ui/core/Input';
import { Button } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


class approvedList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            
        };
    }



    render() {
        return (
            <div>
		  	<div className="common-table-wrap user-table">
		  		<div className="container">
		  			<div className="common-table approved-list-table">
				  		<TableContainer >
				  			<div className="page-title d-flex align-items-center justify-content-between">
			        			<h1>Approved List</h1>
			        		</div>
					      <Table  aria-label="simple table">
					        <TableHead>
					          <TableRow>
					            <TableCell>Account</TableCell>
						            <TableCell align="left">Website</TableCell>
						            <TableCell align="left">Instagram</TableCell>
						            <TableCell align="left">Email</TableCell>
						            <TableCell align="left">Purpose</TableCell>
						 
					          </TableRow>
					        </TableHead>
					        <TableBody>
					            <TableRow>
					              <TableCell  className="user-detail" component="th" scope="row">
					              <b className="d-flex align-items-center" style={{fontSize:"16px", fontWeight :600}}>Mathew JK</b>
					                Manager<br/>
					                
					              </TableCell>
					              <TableCell align="left">www.hubspire.com</TableCell>
					              <TableCell align="left">@mathew</TableCell>
					              <TableCell align="left">mathew@hubspire.com</TableCell>
					              <TableCell align="left">Brand collabration</TableCell>
					        
					            </TableRow>
					        </TableBody>
					      </Table>
					    </TableContainer>
				    </div>
			    </div>
		  	</div>
	  	</div>
        );
    }
}

export default approvedList;