import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AdminLoginAPI from '../Services/Login.js';
import CustomTextField from '../../Common/Components/TextFields';
import SimpleReactValidator from 'simple-react-validator';
import {Button, CircularProgress, Select, MenuItem, InputLabel, FormControl} from '@material-ui/core';
import axios from 'axios'
import { loginCheck } from '../../actions/loginCheck';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loading: false,
      loginErrorMessage: ''
    };
    this.validator = new SimpleReactValidator();
  }
  submitLogin(){
    if(this.validator.allValid()){
      this.props.loginCheck(this.state.email,this.state.password)
      this.setState({
        loading: false
      })
      {/*AdminLoginAPI(this.state.email,this.state.password)
              .then((response) => {
                axios.defaults.headers.common['Authorization'] = response.data.token;
                localStorage.adminToken = response.data.token;
                localStorage.adminEmail = response.data.user.email;
                window.location.href = "/#/dashboard";
              })
              .catch((error) => {
                this.setState({
                  loading: false, 
                  loginErrorMessage: error.response.data.error.message 
                });
              })
            this.setState({
              loading: true
            });
            */}
    } else {
      this.setState({
        loading: false 
      });
      this.validator.showMessages();
        this.forceUpdate();
    }
  }
  render(){
      return(
        <div className="container-fluid">
          <div className="outerWrap">
            <div className="login-wrap">
                <h1>Login</h1>
                <span className="d-block login-sub-text">Please use your email & password to login.</span>
                <form noValidate>
                  <div>
                    <CustomTextField autoFocus={true} type="email" label="Email" className="loginFormBox" value={this.state.email} 
                      onChange={(val)=> this.setState({email: val})} color="red"
                      helperText={this.validator.message('email', this.state.email, 'required|email')}
                    />
                  </div>
                  <div>
                    <CustomTextField type="password" label="Password" className="loginFormBox" value={this.state.password} 
                      onChange={(val)=> this.setState({password: val})}
                      helperText={this.validator.message('password', this.state.password, 'required')}
                    />
                  </div>
                  {this.state.loginErrorMessage.length > 0 && (
                      <div className="srv-validation-message">{this.state.loginErrorMessage}</div>
                  )}
        
                    <Button className="loginSubBtn" disabled={this.state.loading} variant="contained" fullWidth={true} onClick={() =>this.submitLogin()} color="primary">
                      LOG IN{this.state.loading && <CircularProgress size={18} style={{ "color": "grey", "marginLeft": "12px" }} />}
                    </Button>
                    
                </form>
            </div>
          </div>
        </div>
      )
  }
}
function mapStateToProps(state) {
  return {};
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ loginCheck: loginCheck }, dispatch);
}
export default connect(mapStateToProps, matchDispatchToProps)(Login);
