import { apiURL } from '../../env'
import axios from 'axios'

export default function getApprovedAccounts() {
		return axios({
			url: apiURL+"commonData/getData",
	    	method: "POST",
	    	headers: {
	            'Content-Type': 'application/json'
	        },
	    	data: {
	    		"getDataFields": ["getAllDesignations", "getAllPlatforms", "getAllSectors", "getAllAssociates", "getAllAlertsAndNotifications", "getAudiancePersona", "getDataForProfileSetup","getAllSectors","getAllBusinessSectorAndSub", "getPitchType"], 
	    		"skip": 0
	    	}
		})
}