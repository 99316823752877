import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Avatar } from '@material-ui/core'; 

import moment from 'moment';

import DashboardApi from '../services/dashboardApi.js'; 

class dashBoard extends Component {
    constructor(props) {
        super(props);

        this.state = {
             dashboardData:''
        };
    }

    componentDidMount(){
        this.getDashboardData();
    }
    getDashboardData(){
        DashboardApi()
            .then((response) => {
                this.setState({
                    dashboardData: response.data 
                });
            })
            .catch((error) => {
                console.log("error: ",error)
            })
    }

    render() {
        return (
            <div className="dashboad"> 
                <div className="dash_widgets">
    		  	   <div className="container">
                        <div className="row ">
                            <div className="col-sm-4">
                                <h2><span><img src="./assets/images/totalaccounts.svg" className="img-fluid"/> Total Accounts</span><count>{this.state.dashboardData.accountCount}</count></h2>
                            </div>
                            <div className="col-sm-4">
                                <h2><span><img src="./assets/images/events_calender.svg" className="img-fluid"/> Total Events</span><count>{this.state.dashboardData.eventCount}</count></h2>
                            </div>
                            <div className="col-sm-4">
                                <h2><span><img src="./assets/images/campaigns_icon.svg" className="img-fluid"/> Total Pitches</span><count>{this.state.dashboardData.pitchCount}</count></h2>
                            </div>
                        </div>       
                   </div>
                </div>
                <div className="dash_tables">
                   <div className="container">
                   {this.state.dashboardData && (
                        <div className="row ">
                            <div className="col-sm-4">
                                <h3>Latest Accounts <a href="/#/accounts">View More</a></h3>
                                <ul className="dash_tables_list account_list">
                                {this.state.dashboardData.accounts.map((row) => (
                                    <li>
                                        <a href="#">
                                            {row.profileImageUrl && (
                                                <Avatar variant="square" className="mr-2">
                                                    <img src={row.profileImageUrl} className="img-fluid"/>
                                                </Avatar>
                                            )}
                                            {!row.profileImageUrl && (
                                                <Avatar variant="square" className="mr-2">
                                                  {row.firstName[0].toUpperCase()}{row.lastName[0].toUpperCase()}
                                                </Avatar>
                                            )}
                                            <div>
                                                <h4>{row.firstName} {row.lastName}</h4>
                                                {row.location && row.location.length > 0 && (
                                                    <span>{row.location[0].locationString.substring(0, 60)}</span>
                                                )}
                                            </div>
                                        </a>
                                    </li> 
                                ))}
                                    
                                     
                                </ul>
                            </div> 
                        
                            <div className="col-sm-4">
                                <h3>Accounts by specializations</h3>

                                <ul className="dash_tables_list">
                                {this.state.dashboardData.associates.map((row) => (
                                    <li className="d-flex justify-content-between">        
                                        <h4 style={{paddingRight: '15px'}}>{row.name}</h4>
                                        <span style={{fontWeight: 700}}>{row.count}</span>
                                    </li>
                                ))}
                                   
                                </ul>

                            </div> 
                            <div className="col-sm-4">
                                <h3>Latest Pitches <a HREF="/#/pitches-campaigns">View More</a></h3>
                                    <ul className="dash_tables_list">
                                        {this.state.dashboardData.pitches.map((row) => (
                                            <li><a href="#">
                                                    {row.bannerImageUrl && (
                                                        <Avatar variant="circle" className="mr-2"> 
                                                            <img src={row.bannerImageUrl} className="img-fluid pro-pic" alt="web"/>
                                                        </Avatar>
                                                    )}
                                                     <div>
                                                        <h4>{row.titleOfThePitch}</h4>
                                                        <div className="dashboad-event-date-time">
                                                            <span><img src="./assets/images/date-icon.svg" className="img-fluid" alt="web"/>{ moment(row.pitchProposedDate).format('ll') }</span>
                                                            <span style={{textTransform:'capitalize'}}><img src="./assets/images/location-pin.svg" className="img-fluid" alt="web"/><t>{row.pitchLocations.locationString}</t></span>
                                                        </div>
                                                     </div>

                                                </a>
                                            </li>
                                        ))} 
                                    </ul> 

                            </div>
                            </div>
                        )} 

                    </div> 
                </div>
            </div>
        );
    }
}

export default dashBoard;