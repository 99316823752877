// Staging URL
// const apiURL ="https://api-stag.thecaviarspoon.com/api/v1/";

// Production URL
const apiURL ="https://api.thecaviarspoon.com/api/v1/";

// Dev URL
// const apiURL ="https://dev-ser-caviarspoon.herokuapp.com/api/v1/";

export { apiURL }
