import React,{Component} from 'react' 
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import getAuthentication from '../actions/verifyToken';

class MainHeader extends Component{
    constructor(props){
        super(props)
        this.state = {
            loggedIn : false,
            menuState : false
        }
    }

    componentDidMount(){
        if(localStorage.adminToken){
            this.setState({
                loggedIn: true
            });
        } else {
            this.setState({
                loggedIn: false 
            });
        }
    }
    logout(){
        localStorage.clear();
        this.props.getAuthentication();
        window.location.href="/#/";
    }    
   
    render(){
        return(
            <div>
                <header className="fixed-top">
                    <div className="container-fluid">
                        <div className="header-outer">
                            <div className="header-left">
                                <a href="/#/dashboard" className="logo"><img src="./assets/images/logo.png" className="img-fluid" alt="logo" /></a>
                                <ul>

                                    <li><a href="/#/dashboard">Dashboard</a></li>
                                    <li><a href="/#/review">Review</a></li>
                                    <li><a href="#/accounts">Accounts</a></li>
                                    <li><a href="/#/pitches-campaigns">Pitches</a></li>
                                </ul>
                            </div>
                            {this.state.loggedIn && (
                                <div className="header-right"> 
                                    <a onClick={() => this.logout()} className="header-btn header-btn-highlight" style={{color: 'white', borderColor: 'white'}}>Log Out</a>
                                </div>
                            )}

                        </div>
                    </div>
                </header>
            </div>
        )
    }
}


function mapStateToProps(state) {
  return {
    auth: state.auth
  };
}
function matchDispatchToProps(dispatch) {
  return bindActionCreators({getAuthentication: getAuthentication}, dispatch);
}
export default connect(mapStateToProps, matchDispatchToProps)(MainHeader);