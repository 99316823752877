import React, { Component, Suspense } from 'react';


class LoginHeader extends Component {

	render(){
	  return (
	  		<header className="fixed-top">
	            <div className="container-fluid">
	                <div className="header-outer">
	                    <div className="header-left">
	                        <a href="#" className="logo"><img src="./assets/images/logo.png" className="img-fluid" alt="logo" /></a>
	                    </div>
	                </div>
	            </div>
	        </header>
	  	);
  	}
}

export default LoginHeader;

