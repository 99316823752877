import axios from 'axios'
import { apiURL } from '../env'
import {startRequest,endRequest,endRequestError} from './app';
export default function getAuthentication(){
	return dispatch =>{
        dispatch(startRequest())
        if(localStorage.adminToken){
	        axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.adminToken;
		    axios.get(apiURL+ 'users/me')
				.then((response) =>{
					dispatch({
						type:"GET_AUTHENTICATION",
						payload: { user: response.data, status: true, loaded: true, errorMessage: null } 
					})
				})
				.catch((error) => {
					console.log("error: ",error)
					dispatch({
						type:"GET_AUTHENTICATION",
						payload: { user: null, status: false, loaded: true, errorMessage: null } 
					})
					window.location.href = '/#/login';	
				})
	            dispatch(endRequest())
        }  else {
        	dispatch({
				type:"GET_AUTHENTICATION",
				payload: { user: null, status: false, loaded: true, errorMessage: null } 
			})
	        dispatch(endRequest())
        }
	}
}
