import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Paper from '@material-ui/core/Paper';
import ButtonBase from '@material-ui/core/ButtonBase';
import Input from '@material-ui/core/Input';
import { Button, Avatar, TablePagination, TableFooter } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import AccountListAPI from '../services/approvedAccounts.js';
import moment from  'moment';

import TablePaginationActions from '../../Common/Components/Pagination.js';
import {fade,ThemeProvider,withStyles,makeStyles,createMuiTheme,} from '@material-ui/core/styles'
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            backgroundColor: 'red',
             backgroundColor: '#ffffff',
			    color:'rgba(0,0,0,.87)',
			    
			    '&:hover': {
			      backgroundColor: '#fff',
			    },
			    '&$focused': {
			      backgroundColor: '#fff',
			    },
        },
    },
};


class accountList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchIndustry: '',
            searchSpecialisation: '',
            searchLocation:'',
            searchPaymentPreference:'',
            accountsCount: 0,
            accountsList: [],
            limit: 10,
            skip: 0,
            currentPage: 0
        };
    }

    componentDidMount(){
    	this.getAccounts();
    }
    getAccounts(){
    	AccountListAPI(this.state.skip)
    		.then((response) => {
    			this.setState({
    				accountsCount: response.data.count,
    				accountsList: response.data.data
    			});
    		})
    		.catch((error) => {
    			console.log("error: ",error)
    		})
    }
    changePage(val){
    	this.setState({
    		currentPage: val,
    		skip: val*10
    	},this.getAccounts)
    }
    render() {
        return (
            <div>
		  	<div className="account-search d-none">
		  		<div className="container">
		  			<div className="search-wrap">
		  				<div className="search-item">
		        			<FormControl variant="outlined" className="dropdown-arrow-color">
						        <InputLabel id="demo-simple-select-label">Industry</InputLabel>
						        <Select 
						          labelId="demo-simple-select-label"
						          id="demo-simple-select"
						          label="Industry"
						          MenuProps={MenuProps}
						          value={this.state.searchIndustry}
						          onChange={(event) => this.setState({searchIndustry: event.target.value})}
						        >
						          <MenuItem value="Fastion">Fastion</MenuItem>
						          <MenuItem value="Fastion">Fastion</MenuItem>
						        </Select>
						      </FormControl>
		        		</div>
		        		<div className="search-item">
		        			<FormControl variant="outlined" className="dropdown-arrow-color">
						        <InputLabel id="demo-simple-select-label">Specialisation</InputLabel>
						        <Select 
						          labelId="demo-simple-select-label"
						          id="demo-simple-select"
						          label="Specialisation"
						          MenuProps={MenuProps}
						          value={this.state.searchSpecialisation}
						          onChange={(event) => this.setState({searchSpecialisation: event.target.value})}
						        >
						          <MenuItem value="Stylist">Stylist</MenuItem>
						          <MenuItem value="Stylist">Stylist</MenuItem>
						        </Select>
						      </FormControl>
		        		</div>
		        		<div className="search-item">
		        			<FormControl variant="outlined" className="dropdown-arrow-color">
						        <InputLabel id="demo-simple-select-label">Location</InputLabel>
						        <Select 
						          labelId="demo-simple-select-label"
						          id="demo-simple-select"
						          label="Location"
						          MenuProps={MenuProps}
						          value={this.state.searchLocation}
						          onChange={(event) => this.setState({searchLocation: event.target.value})}
						        >
						          <MenuItem value="new-york">New York</MenuItem>
						          <MenuItem value="new-jersey">New Jersey</MenuItem>
						        </Select>
						      </FormControl>
		        		</div>
		        		<div className="search-item">
		        			<FormControl variant="outlined" className="dropdown-arrow-color">
						        <InputLabel id="demo-simple-select-label">Paymen Preference</InputLabel>
						        <Select 
						          labelId="demo-simple-select-label"
						          id="demo-simple-select"
						          label="Payment Preference"
						          MenuProps={MenuProps}
						          value={this.state.searchPaymentPreference}
						          onChange={(event) => this.setState({searchPaymentPreference: event.target.value})}
						        >
						          <MenuItem value="pp-1">Paymen Preference 1</MenuItem>
						          <MenuItem value="pp-2">Paymen Preference 2</MenuItem>
						        </Select>
						      </FormControl>
		        		</div>
		        		<div className="search-item">
		        		<Button color="primary" className="button-solid">
		                        Search
		                    </Button>
		        		</div>
		  			</div>
		  		</div>
		  	</div>
		  	<div className="common-table-wrap">
		  		<div className="container">
		  			<div className="common-table">
				  		<TableContainer >
					      <Table  aria-label="simple table">
					      	{this.state.accountsList.length <= 0 && (
					        	<caption style={{textAlign: 'center'}}>No accounts found.</caption>
					        )}
					        <TableHead>
					          <TableRow>
					            <TableCell align="left">Account Owner</TableCell>
					            <TableCell align="left">Email</TableCell>
					            <TableCell align="left">Last Activity</TableCell>
					            <TableCell align="left">Status</TableCell>
					            <TableCell align="left">Type</TableCell>
					            <TableCell align="right"></TableCell>
					          </TableRow>
					        </TableHead>
					        {this.state.accountsList.length > 0 && (
						        <TableBody>
						        	{this.state.accountsList.map((record, index) => {
						        		return(
						        			<TableRow key={index}>
								              <TableCell component="th" scope="row">
								              <div className="table-profile-details d-flex align-items-start">
								                {record.owner.profileImageUrl && (
								                	<Avatar className="mr-2" variant="square">
								                		<img src={record.owner.profileImageUrl} className="img-fluid mr-0"/>
								                	</Avatar>
								                )}
								                {!record.owner.profileImageUrl && (
								                	<Avatar className="mr-2" variant="square">
													  {record.owner.firstName[0].toUpperCase()}{record.owner.lastName[0].toUpperCase()}
													</Avatar>
								                )}
								                <div>
								                	{record.owner.profileSetupStatus === 'complete' && record.platform.name === 'Collaborations' && (
								                		<label className="d-block" style={{textTransform:'capitalize'}}><a href={"https://app.thecaviarspoon.com/profile-view/"+record.owner._id} target="_blank">{record.owner.firstName} {record.owner.lastName}</a></label>
								                	)}
								                	{record.owner.profileSetupStatus !== 'complete' && (
								                		<label className="d-block" style={{textTransform:'capitalize'}}>{record.owner.firstName} {record.owner.lastName}</label>
								                	)}
								                	{record.owner.designation}<br/>
									                {record.website && (
									                	<a href={'//'+record.website} target="_blank">{record.companyName}</a>
									                )}
									                {!record.website && (
									                	<a>{record.companyName}</a>
									                )}
								                </div>
								              </div>
								              </TableCell>								              
								              <TableCell align="left"><a href={"mailto:"+record.owner.email}>{record.owner.email}</a></TableCell>
								              <TableCell align="left">{moment(record.owner.lastUpdatedDate).format('MMM Do YYYY')}</TableCell>
								              <TableCell align="left">{record.owner.profileSetupStatus}</TableCell>
								              <TableCell align="left">{record.platform.name}</TableCell>
								              <TableCell className="action-btn" align="right">
								              	
									              	<Button aria-label="View" color="primary" disabled={(record.owner.profileSetupStatus !== 'complete' || record.platform.name !== 'Collaborations')} onClick={() => window.open("https://app.thecaviarspoon.com/profile-view/"+record.owner._id) }>
									              		View
									              	</Button>
								              </TableCell>
								            </TableRow>
						        		)
						        	})}
						        </TableBody>
						       )}
					        <TableFooter>
					          <TableRow>
					            <TablePagination
					              rowsPerPageOptions={[10]}
					              colSpan={4}
					              count={this.state.accountsCount}
					              rowsPerPage={10}
					              page={this.state.currentPage}
					              onChangePage={(value) => this.changePage(value)}
					              ActionsComponent={TablePaginationActions}
					            />
					          </TableRow>
					        </TableFooter>
					      </Table>
					    </TableContainer>
				    </div>
			    </div>
		  	</div>
	  	</div>
        );
    }
}

export default accountList;