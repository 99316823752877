import { apiURL } from '../../env'
import axios from 'axios'
export default function getPitchCampaignsData(token, skip, type) {
	let getURL;
	if(type == 'pitches'){
		getURL = apiURL+'admin/pitches?skip='+skip;
	} else {
		getURL = apiURL+'admin/pitches?skip='+skip+'&pitchType='+type;
	}
    axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.adminToken;
    return axios.get(getURL)
}