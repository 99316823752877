 const REQUEST_START = 'REQUEST_START'
export function startRequest(subreddit) {
  return {
    type: REQUEST_START
  }
}

 const REQUEST_END = 'REQUEST_END'
export function endRequest(subreddit) {
  return {
    type: REQUEST_END
  }
}
const REQUEST_ERROR = 'REQUEST_ERROR'
export function endRequestError(subreddit) {
  return {
    type: REQUEST_ERROR
  }
}