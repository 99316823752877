import { apiURL } from '../../env'
import axios from 'axios'
export default function approveRejectUser(id, status, platformId){
    axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.adminToken;
	return axios({
		'method': 'POST',
		'url': apiURL+'users/setAdminApproval',
    	'data': {
    		"userId": id,
    		"approve": status,
    		"platformId": platformId
    	}
	})
}